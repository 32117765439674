angular.module('BillPay')
    .factory('ProviderModel', function (CoreModel, PAYMENT_METHOD, $log, _, $filter) {

        return CoreModel('provider', {

            deserialize: function (data) {
                this.urlSlug = data.urlSlug;
                this.patientWalletUrl = data.patientWalletUrl;
                this.name = data.name.trim();
                this.phone = data.phone;
                this.primaryColor = data.primaryColor;
                this.secondaryColor = data.secondaryColor;
                this.accountNumberRegex = data.accountNumberRegex || '.*';
                this.accountNumberErrorMessage = data.accountNumberErrorMessage ? data.accountNumberErrorMessage.trim() : '';
                this.limits = {
                    paymentMaxScheduleDays: data.paymentMaxScheduleDays ? data.paymentMaxScheduleDays : 42,
                    minimumPaymentAmount: data.minimumPaymentAmount ? data.minimumPaymentAmount : 0.01,
                    maximumPaymentAmount: data.maximumPaymentAmount ? data.maximumPaymentAmount : 1500,
                    methods: [PAYMENT_METHOD.CREDIT, PAYMENT_METHOD.ECHECK]
                };
                this.billPayEnabled = data.billPayEnabled;
                this.guestPayEnabled = data.guestPayEnabled;
                this.mobilePayEnabled = data.mobilePayEnabled;
                this.premiumPaymentsEnabled = data.premiumPaymentsEnabled;
                this.isBranded = data.isBranded;
                this.allowInternationalPayments = data.internationalPaymentsInBillpay;
                this.accountNumberLabel = data.accountNumberLabel;
                this.memberIdLabel = data.memberIdLabel;
                this.invoiceNumberLabel = data.invoiceNumberLabel;
                this.memberIdRegex = data.memberIdRegex;
                this.memberIdErrorMessage = data.memberIdErrorMessage;
                this.invoiceNumberRegex = data.invoiceNumberRegex;
                this.invoiceNumberErrorMessage = data.invoiceNumberErrorMessage;
                this.ticketNumberLabel = data.ticketNumberLabel;

                if(!data.accountNumberLabel || data.accountNumberLabel === ''){
                    this.accountNumberLabel=$filter('translate')('labels.accountNumber');
                }
            },

            serialize: function () {
                /*eslint no-unused-vars: [0, { "vars": "local" }]*/
                var toReturn = {
                    urlSlug: this.urlSlug,
                    patientWalletUrl: this.patientWalletUrl,
                    name: this.name,
                    phone: this.phone,
                    primaryColor: this.primaryColor,
                    secondaryColor: this.secondaryColor,
                    accountNumberRegex: this.accountNumberRegex,
                    accountNumberErrorMessage: this.accountNumberErrorMessage,
                    accountNumberLabel: this.accountNumberLabel,
                    paymentMaxScheduleDays: this.limits.paymentMaxScheduleDays,
                    minimumPaymentAmount: this.limits.minimumPaymentAmount,
                    maximumPaymentAmount: this.limits.maximumPaymentAmount,
                    supportsEchecks: !!~this.limits.methods.indexOf(PAYMENT_METHOD.ECHECK),
                    billPayEnabled: this.billPayEnabled,
                    isBranded: this.isBranded
                };
            },

            allowsPaymentType: function (paymentType) {
                return !!~this.limits.methods.indexOf(paymentType);
            }

        });

    });
